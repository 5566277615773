@import '../../scss/imports';

#home {
  display: flex;
  margin-top: rem(16px);

  .events-container {
    flex-grow: 1;
    position: relative;
  }

  h1 {
    font: rem(24px) $font-regular;
    line-height: rem(56px);
    margin: 0;

    @include min-screen($ipad) {
      font-size: rem(38px);
    }
  }

  .top-action-bar {
    margin: 0 0 rem(20px 0);
  }

  .action-button {
    color: $secondary-text-color;

    &.large {
      padding: rem(4px);

      .md-icon-text {
        padding-left: rem(8px);
      }
    }

    &.active {
      color: $action-color;
    }
  }

  .wrapper {
    max-width: rem(1052px);
  }

  .search-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include max-screen($smt) {
      width: 100%;
    }

    .search-input-container {
      flex: 0 1 auto;
      overflow-x: scroll;
      padding-right: rem(10px);
      position: relative;
      width: 100%;
    }

    .toggle-filter-button {
      flex: 0 1 rem(24px);
    }
  }

  .closed {
    display: none;
  }

  .open {
    @include max-screen($sm) {
      max-width: calc(100vw - 32px);
      overflow-x: scroll;
    }
  }

  .filter-chip-container {
    margin-bottom: 0;
  }

  .page-title {
    color: $secondary-text-color;
    font-family: $font-regular;
    font-size: rem(38px);
    margin: 0;

    @include max-screen($xs) {
      font-size: rem(20px);
    }
  }

  .main-action-button {
    bottom: rem(20px);
    position: fixed;
    right: rem(32px);
    z-index: 9999;
  }
}
