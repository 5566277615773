@import '../../scss/imports';

$border: 1px solid #DEDEDE;

.ph-table {
  margin-bottom: rem(8px);
  overflow-x: auto;

  table {
    width: 100%;
  }

  thead {
    background: #F2F2F2;
    border-bottom: $border;
    border-top: $border;
    font-family: $font-demi;
  }
  .date{
    text-transform: lowercase;
  }

  th,
  td {
    color: $secondary-text-color;
    font-size: rem(14px);
    padding: rem(16px);
    text-align: left;
  }

  .disabled-sort {
    cursor: default;
  }

  th {
    cursor: pointer;
    font-weight: normal;
    padding: rem(12px) ;
  }

  .th-item {
    display: flex;
    position: relative;

    span {
      line-height: rem(24px);
      vertical-align: middle;
    }

    .th-icon {
      display: inline-block;
      height: rem(24px);
      width: rem(24px);
    }

    .md-icon {
      vertical-align: middle;
    }
  }

  tbody {
    tr {
      border-bottom: $border;
      cursor: pointer;

      &.active {
        background: rgba(#2196F3, 0.2);
      }
    }

    td {
      svg {
        margin: 0 rem(12px);
      }
    }
  }
  .circle-avatar {
      align-items: center;
      border: solid white 1px;
      border-radius: 50%;
      color: white;
      display: flex;
      font-size: rem(14px);
      height: rem(32px);
      justify-content: center;
      margin-right: rem(20px);
      width: rem(32px);
  }

  .avatar-name-container {
    align-items: center;
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
    }
}
