@import '../../scss/imports';

$tab-width: 160px;
$tab-width-mobile: 125px;
$tab-margin: 32px;
$time: 300ms;


.tabs-wrapper {
  background-color: $primary-color-dark;
}

.tab-navigation {
  max-width: rem(1200px);
  margin-left: auto;
  margin-right: auto;
  padding: 0 rem(16px);

  .tab-navigation-menu {
    height: rem(48px);
    position: relative;

    a {
      color: rgba(#FFF, 0.7);
      cursor: pointer;
      display: inline-block;
      font-family: $font-regular;
      font-size: rem(13px);
      font-weight: normal;
      height: rem(48px);
      line-height: rem(48px);
      margin-right: rem($tab-margin);
      min-width: rem($tab-width);
      padding: 0;
      position: relative;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 200ms ease-in-out;

      &:hover {
        color: rgba(#FFF, 0.7);
      }

      &:last-child {
        margin-right: 0;
      }

      .bottom-border {
        background-color: #FFF;
        bottom: 0;
        display: inline-block;
        height: rem(2px);
        left: 50%;
        position: absolute;
        transform: translateX(-50%) scale(0);
        transition: transform $time ease-in-out;
        width: 0;
      }

      &.active {
        color: #FFF;
      }

      @include max-screen($ipad) {
        margin: 0;
        min-width: rem($tab-width-mobile);

        &.active {
          .bottom-border {
            transform: translateX(-50%) scale(1);
            width: rem($tab-width-mobile);
          }
        }
      }
    }

    .link-container {
      display: flex;
      overflow-x: auto;
      position: relative;

      @include min-screen(em(769)) {
        &:after {
          background-color: #FFF;
          bottom: 0;
          content: '';
          display: inline-block;
          height: rem(2px);
          left: 0;
          position: absolute;
          transform: translateX(0);
          transition: transform $time ease-in-out, width $time ease-in-out;
          width: $tab-width;
        }

        &.active-0 {
          &:after {
            transform: translateX(0);
          }
        }

        &.active-1 {
          &:after {
            transform: translateX(calc(#{$tab-width} + #{$tab-margin}));
          }
        }

        &.active-2 {
          &:after {
            transform: translateX(calc((#{$tab-width} + #{$tab-margin}) * 2));
          }
        }
        &.active-3 {
          &:after {
            transform: translateX(calc((#{$tab-width} + #{$tab-margin}) * 3));
          }
        }
      }
    }
  }
}
