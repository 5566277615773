.daily-event-wrapper {
  .action-container {
    align-items: center;
    border-bottom: 1px solid #dedede;
    display: grid;
    grid-column-gap: 1.25rem;
    grid-template-rows: repeat(3, auto);
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    width: 100%;
  }
  .event-card {
    margin: 0 auto;
  }
  .event-list {
    display: grid;
    grid-gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0 0 1rem;
    width: 100%;
  }
  .load-more-button {
    display: block;
    margin: 0 auto;
  }
  .search-input-container {
    max-width: 21rem;
  }
  .view-toggle-button {
    grid-column: 1 / -1;
    justify-self: end;
  }
  @media screen and (min-width: 700px) {
    .event-list.card {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (min-width: 1000px) {
    .event-list.card {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media screen and (min-width: 1200px) {
    .action-container {
      grid-template-columns: 21rem 1fr;
    }
  }
}