@import '../../scss/imports';

.rankings-chart {
  padding: rem(8px);

  .bar-container {
    margin-bottom: rem(12px);
    padding: rem(4px 12px);
    position: relative;

    h4 {
      color: #000;
      font-family: $font-regular;
      font-size: rem(18px);
      line-height: rem(24px);
      margin: 0;
      position: relative;
      z-index: 9;
    }

    p {
      color: rgba(#000, .55);
      font-family: $font-regular;
      font-size: rem(12px);
      line-height: rem(15px);
      margin: 0;
      position: relative;
      z-index: 9;
    }

    .indicator {
      height: 100%;
      width: 0;
      top: 0;
      left: 0;
      opacity: .15;
      position: absolute;
      transition: width 1000ms ease-in-out;
      z-index: 1;
    }
  }
}
