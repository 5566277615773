// remove negative margins on row
.row-clear {
  margin-left: 0;
  margin-right: 0;
}

// collapse all gutters
.collapse {
  margin-left: 0;
  margin-right: 0;

  & > [class*=col-] {
    padding-left: 0;
    padding-right: 0;
  }
}

// collapse gutters on sides
.collapse-sides {
  margin-left: 0;
  margin-right: 0;

  & > [class*=col-] {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

// Add new breakpoint in grid for small-medium-tablet size
@include min-screen(50.063em) {
  @include generate-grid(smt)
}
