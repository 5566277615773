@import 'imports';

//react datepicker variables
$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #216ba5 !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: $border-radius !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 16px !default;
$datepicker__font-family: $font-regular !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

@import '~react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
  border: none;
  box-shadow: 0 0 5px rgba(#000, .36);
}

.react-datepicker__header {
  background-color: #FFF;
  border-bottom: none;
  padding-top: rem(8px);
  position: relative;
  text-align: center;

  .react-datepicker__day-names,
  .react-datepicker__week {
    background-color: #E0E0E0;
    box-shadow: 0 2px 2px rgba(#000, .18);
  }
}

.react-datepicker-popper[data-placement^='bottom'] {
  .react-datepicker__triangle,
  .react-datepicker__triangle::before {
    border-bottom-color: transparent;
  }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  line-height: rem(40px);
  width: rem(40px);

  @include max-screen($sm) {
    line-height: rem(34px);
    width: rem(34px);
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  color: #fff;
}
.react-datepicker__day--in-range {
  border-radius: 50%;
  color: #fff;
}


.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #2196F3;
  border-radius: 50%;
  color: #fff;
}

.react-datepicker__navigation  {
  top: rem(20px);
}

.react-datepicker__day:hover {
  border-radius: 50%;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  line-height: rem(40px);
}

.react-datepicker__month--selecting-range
.react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #d1d1d1;
  color: #000;
}
