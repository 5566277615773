@import '~leaflet/dist/leaflet.css';
@import '~react-leaflet-markercluster/dist/styles.min.css';

.leaflet-tile-pane {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.leaflet-container {
  height: 620px;
  width: 100%;
}

.leaflet-pane {
  z-index: 18;
}

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  span {
    color: #FFF;
  }
}

.marker-cluster-small {
  background-color: rgba(0, 0, 0, 0.1);

  div {
    background-color: #979797;
  }
}

.marker-cluster-medium {
  background-color: rgba(0, 0, 0, 0.3);

  div {
    background-color: #646464;
  }
}

.marker-cluster-large {
  background-color: rgba(0, 0, 0, 0.4);

  div {
    background-color: #464646;
  }
}
