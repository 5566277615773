@import '../../scss/imports';


.subheader-container {
  font-family: $font-regular;
  color: $white;
  padding: rem(16px);
  position: relative;
  background-color: $primary-color;

  @include max-screen($smt) {
    display: none;
  }

  .back-button {
    height: rem(32px);

    padding: rem(4px 0);
    position: absolute;
    top: rem(28px);
    width: rem(32px);
  }

  .subheader-button {
    color: $white;
  }

  h1 {
    font-size: rem(38px);
    line-height: rem(56px);
    margin: 0;
    padding-left: rem(48px);
  }

  .details {
    border-left: 1px solid $light-text-color;
    color: $light-text-color;
    display: inline-block;
    font-size: rem(16px);
    height: rem(24px);
    line-height: rem(24px);
    padding: 0 rem(24px);

    &:first-of-type {
      border: none;
      padding-left: 3rem;
    }
  }
}
