@import '../../scss/imports';

.avg-chart-legend-container {
  display: flex;
  flex-direction: column;
  height: rem(69px);
  padding-bottom: rem(64px);
  width: rem(250px);

  h2, p {
    margin: 0;
  }

  h2 {
    font-size: rem(36px);
  }

  p {
    color: rgba(0, 0, 0, 0.55);
    font-size: rem(17px);
  }

}
