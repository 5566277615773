$font-path: '../lib/fonts' !default;

@font-face{
  font-family:"AvenirNextLTW01-UltraLi";
  src:url("#{$font-path}/17c55b27-e9ab-43cd-b948-d49f97480d68.eot?#iefix");
  src:url("#{$font-path}/17c55b27-e9ab-43cd-b948-d49f97480d68.eot?#iefix") format("eot"),url("#{$font-path}/2e3f5cb9-101f-46cf-a7b3-dfaa58261e03.woff2") format("woff2"),url("#{$font-path}/fa19948e-5e38-4909-b31e-41acd170d6f2.woff") format("woff"),url("#{$font-path}/6de0ce4d-9278-467b-b96f-c1f5f0a4c375.ttf") format("truetype"),url("#{$font-path}/9fd4ea0c-b19a-4b21-9fdf-37045707dd78.svg#9fd4ea0c-b19a-4b21-9fdf-37045707dd78") format("svg");
}
@font-face{
  font-family:"Avenir Next W01 Light";
  src:url("#{$font-path}/0ecef829-586e-426d-a633-4c8a8c926769.eot?#iefix");
  src:url("#{$font-path}/0ecef829-586e-426d-a633-4c8a8c926769.eot?#iefix") format("eot"),url("#{$font-path}/49c5f3e1-5867-4b1d-a843-2d07fa60d85d.woff2") format("woff2"),url("#{$font-path}/f401df6f-54eb-406c-b46a-90b9451c598d.woff") format("woff"),url("#{$font-path}/276fe25e-e430-4376-b33c-705dd77b3dba.ttf") format("truetype"),url("#{$font-path}/869dc33b-3685-4d74-a51c-82b435579093.svg#869dc33b-3685-4d74-a51c-82b435579093") format("svg");
}
@font-face{
  font-family:"AvenirNextLTW01-Regular";
  src:url("#{$font-path}/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
  src:url("#{$font-path}/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix") format("eot"),url("#{$font-path}/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"),url("#{$font-path}/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"),url("#{$font-path}/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype"),url("#{$font-path}/52a192b1-bea5-4b48-879f-107f009b666f.svg#52a192b1-bea5-4b48-879f-107f009b666f") format("svg");
}
@font-face{
  font-family:"Avenir Next LT W01 Demi";
  src:url("#{$font-path}/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix");
  src:url("#{$font-path}/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix") format("eot"),url("#{$font-path}/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"),url("#{$font-path}/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"),url("#{$font-path}/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype"),url("#{$font-path}/99affa9a-a5e9-4559-bd07-20cf0071852d.svg#99affa9a-a5e9-4559-bd07-20cf0071852d") format("svg");
}
@font-face{
  font-family:"AvenirNextLTW01-Heavy";
  src:url("#{$font-path}/3418f6be-70a5-4c26-af1d-c09a8642ca20.eot?#iefix");
  src:url("#{$font-path}/3418f6be-70a5-4c26-af1d-c09a8642ca20.eot?#iefix") format("eot"),url("#{$font-path}/5c57b2e2-f641-421e-a95f-65fcb47e409a.woff2") format("woff2"),url("#{$font-path}/181c847e-cdbc-43d5-ae14-03a81c8953b4.woff") format("woff"),url("#{$font-path}/045d1654-97f2-4ff0-9d24-21ba9dfee219.ttf") format("truetype"),url("#{$font-path}/3c111f4f-c9f7-45d4-b35f-4f4ed018842f.svg#3c111f4f-c9f7-45d4-b35f-4f4ed018842f") format("svg");
}
@font-face{
  font-family:"Avenir Next Cyr W00 Ult Light";
  src:url("#{$font-path}/c0188ad0-247f-477d-9526-50b274232352.eot?#iefix");
  src:url("#{$font-path}/c0188ad0-247f-477d-9526-50b274232352.eot?#iefix") format("eot"),url("#{$font-path}/e752a633-50ba-41e9-a13a-5ebf7e6042e4.woff2") format("woff2"),url("#{$font-path}/cf48de9f-77b6-4dd5-acd2-2b8801621202.woff") format("woff"),url("#{$font-path}/8a62c8f4-04dc-45ea-b84f-7dddcd27afad.ttf") format("truetype"),url("#{$font-path}/a9b6db18-6b5d-4209-8eef-cf22422bc83e.svg#a9b6db18-6b5d-4209-8eef-cf22422bc83e") format("svg");
}
@font-face{
  font-family:"Avenir Next Cyr W00 Light";
  src:url("#{$font-path}/5db267f9-3612-485c-ae18-5698d2692816.eot?#iefix");
  src:url("#{$font-path}/5db267f9-3612-485c-ae18-5698d2692816.eot?#iefix") format("eot"),url("#{$font-path}/174d458a-81e0-4174-9473-35e3bf0a613c.woff2") format("woff2"),url("#{$font-path}/57a79aa3-9b06-4ba7-a9a4-2b766d826ecf.woff") format("woff"),url("#{$font-path}/733cb7bd-50e1-4dee-893a-0b40ef382b02.ttf") format("truetype"),url("#{$font-path}/594135c6-6c4f-4880-a0d2-ba923b5ef38e.svg#594135c6-6c4f-4880-a0d2-ba923b5ef38e") format("svg");
}
@font-face{
  font-family:"Avenir Next Cyr W00 Regular";
  src:url("#{$font-path}/069faa0e-9913-48c4-9ef7-89a4bc080b65.eot?#iefix");
  src:url("#{$font-path}/069faa0e-9913-48c4-9ef7-89a4bc080b65.eot?#iefix") format("eot"),url("#{$font-path}/7db1f672-3a8f-4d19-9c49-7f61aed450b5.woff2") format("woff2"),url("#{$font-path}/4ab86b35-c0c2-42b5-98ad-4b6eba66b197.woff") format("woff"),url("#{$font-path}/276b3566-1c3b-4bc1-8915-15314f091f29.ttf") format("truetype"),url("#{$font-path}/5d02f5f4-46e7-453a-aef9-3e7106d7bb68.svg#5d02f5f4-46e7-453a-aef9-3e7106d7bb68") format("svg");
}
@font-face{
  font-family:"Avenir Next Cyr W00 Demi";
  src:url("#{$font-path}/fbf4298d-ef75-419c-b615-47f209a48ed2.eot?#iefix");
  src:url("#{$font-path}/fbf4298d-ef75-419c-b615-47f209a48ed2.eot?#iefix") format("eot"),url("#{$font-path}/40d36b4a-60c6-460a-bf43-4c948c23563e.woff2") format("woff2"),url("#{$font-path}/45b78f45-e639-4836-8612-e0892e120f14.woff") format("woff"),url("#{$font-path}/cbc021cc-b96f-4c82-ba0d-59cb0b7449c0.ttf") format("truetype"),url("#{$font-path}/c6778bcf-ca82-4a86-8a5f-08b70c9a71fd.svg#c6778bcf-ca82-4a86-8a5f-08b70c9a71fd") format("svg");
}
@font-face{
  font-family:"Avenir Next Cyr W00 Heavy";
  src:url("#{$font-path}/ef4cad7e-cd09-4c64-ade9-5c99ffec032c.eot?#iefix");
  src:url("#{$font-path}/ef4cad7e-cd09-4c64-ade9-5c99ffec032c.eot?#iefix") format("eot"),url("#{$font-path}/9590a83b-5072-43f4-b321-296d75e96d7d.woff2") format("woff2"),url("#{$font-path}/fe3b4b6f-0633-4d79-8915-4fa24db0c0cc.woff") format("woff"),url("#{$font-path}/2f549da8-c9f2-45e3-8fe0-8491cb395935.ttf") format("truetype"),url("#{$font-path}/d7af93d6-04f7-48a4-88a5-6e1a5ba5754f.svg#d7af93d6-04f7-48a4-88a5-6e1a5ba5754f") format("svg");
}
