@import '../../scss/imports';

#transaction-dialog {

  h4 {
    color: $secondary-text-color;
  }
  .icon {
    left: 0;
    position: absolute;
    top: rem(3px);
  }

  .data {
    .metric {
      color: #000;
      display: flex;
      font-family: $font-regular;
      font-size: rem(24px);
      line-height: rem(30px);
      text-transform: capitalize;
    }

    .label {
      color: rgba(#000, .55);
      display: flex;
      font-family: $font-regular;
      font-size: rem(12px);
      line-height: rem(16px);
    }
  }

  ul {
    list-style: none;
    padding: rem(16px) rem(16px) 0 rem(16px);
    margin-top: rem(60px);

    li {
      min-height: rem(45px);
      margin-bottom: rem(8px);
      position: relative;
      padding-left: rem(40px);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .md-dialog-content {
    overflow: hidden;
  }

  .md-title--toolbar {
    margin-top: 0;
  }

  .md-btn--toolbar {
    margin-bottom: rem(14px);
    margin-top: rem(14px);

    i {
      vertical-align: middle;
    }
  }

  .wrapper {
    padding-top: rem(32px);
  }

  .widget-gallery-container {
    background-color: #394242;
    min-height: 100%;
    width: 100vw;
    color: white;
    box-sizing: border-box;

    .widget-gallery {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      padding: 0;
    }
  }
}
