@import '../../scss/imports';

#layout {
  margin-top: rem(24px);

  .wrapper {
    width: 100%;
  }

  .top-action-bar {
    margin: 0 0 rem(24px 0);

    .action-container {
      margin-bottom: rem(16px);

      @include max-screen(1199px) {
        margin-bottom: 0;
      }
    }
  }

  .closed {
    display: none;
  }

  .section-title {
    h4 {
      color: $secondary-text-color;
      font-family: $font-demi;
      font-size: rem(18px);
      line-height: rem(36px);
      margin: 0;
      text-align: left;
    }
  }

  .chart-container {
    column-count: 3;
    column-gap: rem(1px);

    @media(max-width:800px) {
      column-count: 2;
      column-gap: rem(1px);
    }

    @media(max-width:600px) {
      column-count: 1;
      column-gap: rem(1px);
    }
  }

  .chart-container,
  .content-container {
    &+.top-action-bar {
      margin-top: rem(16px);
    }
  }

  .chart-column {
    display: flex;
    flex-direction: column
  }

  .overview-actions-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .action-container {
    align-items: center;
    box-sizing: border-box;
    color: rgba(0,0,0,0.6);
    display: flex;
    justify-content: space-between;

    .action-button {
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .load-more-container {
    margin-top: rem(48px);
  }
}

#transactions {

  .top-action-bar {

    .action-container {
      justify-content: flex-end;
      margin-bottom: rem(16px);
      text-align: end;

      @include max-screen(1199px) {
        margin-bottom: 0;
      }
    }
  }
}

.no-data-error {
  color: #bbbbbb;
}
