// Variable overrides

@import '~react-md/src/scss/react-md';

$md-colors-warn-md: false;
$md-secondary-color: $secondary-color;
$md-primary-color: $primary-color;
$md-subheading-mobile-font-size: rem(16px);
$md-light-theme-background-color: $body-bg-color;
$md-list-desktop-font-size: rem(18px);
$md-font-name: 'AvenirNextLTW01-Regular';
$md-font-size-base: 16;
$md-body-desktop-font-size: rem(16px);
$md-light-theme-text-color: $secondary-text-color;
$md-tab-indicator-height: rem(4px);
$md-tab-max-width: rem(180px);
$md-light-theme-colors: (text: $md-light-theme-text-color, secondary: $md-light-theme-secondary-text-color, icon: $md-light-theme-icon-text-color, disabled: $md-light-theme-disabled-text-color, hint: $md-light-theme-hint-text-color, divider: $md-light-theme-divider-color, background: $md-light-theme-background-color, card: $md-light-theme-card-color, hover: $md-light-theme-divider-color, toolbar: $md-light-theme-app-bar-color);

// @include react-md-typography;
@include react-md-accessibility;

@include react-md-grid;
@include react-md-transitions;

// @include react-md-autocompletes;
@include react-md-avatars;

// @include react-md-badges;
// @include react-md-bottom-navigations;
@include react-md-buttons;
@include react-md-cards;

// @include react-md-chips;
// @include react-md-collapsers;
// @include react-md-data-tables;
@include react-md-dialogs;
@include react-md-dividers;
@include react-md-drawers;
// @include react-md-expansion-panels;
// @include react-md-file-inputs;
@include react-md-inks;
@include react-md-icons;
@include react-md-layovers;
@include react-md-lists;

// @include react-md-media;
@include react-md-menus;
// @include react-md-navigation-drawers;
@include react-md-overlays;
@include react-md-papers;

// @include react-md-pickers;
// @include react-md-progress;
// @include react-md-select-fields;
@include react-md-selection-controls;
// @include react-md-sliders;
// @include react-md-subheaders;
// @include react-md-snackbars;
@include react-md-tabs;
@include react-md-text-fields;
@include react-md-toolbars;
@include react-md-tooltips;

@include react-md-helpers-everything;
@include react-md-colors;

// override react-md default media query
@media screen and (min-width: 1025px) {
  h5, h4 {
    font-size: rem(16px);
  }

  .md-btn--icon {
    height: rem(48px);
    padding: rem(12px);
    width: rem(48px);

    &.md-btn--floating {
      height: rem(64px);
      width: rem(64px);

      &.md-text--disabled {
        background-color: $disabled-color;
        color: $white;
      }
    }
  }

  .md-btn--text {
    height: rem(32px);
  }

  .md-icon {
    font-size: rem(24px);
  }

  .md-tile-addon--icon {
    height: rem(24px);
  }
}

@media screen and (min-width: 320px) {
  h5, h4 {
    font-size: rem(16px);
  }

  .md-btn-text {
    height: rem(32px);
  }

  .md-btn--icon {
    height: rem(36px);
    width: rem(36px);
    padding: 0;

    &.md-btn--floating {
      height: rem(64px);
      width: rem(64px);
    }
  }

  .md-btn--text {
    height: rem(32px);
  }

  .md-tile-addon--icon {
    height: rem(24px);
  }
}

// react-md-list
.md-list {
  background: $body-bg-color;
}

.md-list-tile {
  height: rem(56px);
  padding-left: rem(24px);
}

.md-tile-content--left-icon {
  padding-left: rem(24px);
}

.md-tile-text--primary {
  font-family: $font-regular;
  font-weight: 600;
}

.md-icon {
  font-size: rem(24px);
}
