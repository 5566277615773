@import '../../scss/imports';

.event-card {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  color:  $secondary-text-color;
  cursor: pointer;
  margin: 0 auto rem(16px) auto;
  max-width: rem(340px);
  overflow: hidden;
  position: relative;

  &.active {
    background-color: rgba(#2196F3, 0.2);
  }
  &> * {
    pointer-events: none;
  }

  .md-card-text {
    padding: rem(16px);
  }

  .event-title {
    margin: 0 auto rem(8px) auto;
    position: relative;

    .material-icons {
      color: $secondary-color;
      left: 0;
      position: absolute;
      top: 0;
    }

    h5 {
      font-family: $font-regular;
      font-size: rem(18px);
      font-weight: 600;
      padding-left: rem(32px);
      margin: 0;
    }
  }

  .details {
    display: inline-block;
    font-size: rem(18px);
    line-height: rem(24px);
    margin: 0 auto rem(8px) auto;
    width: 100%;

    &:last-child {
      margin: 0;
    }

    &.inline {
      border-left: 1px solid $secondary-text-color;
      padding-left: rem(16px);
      padding-right: rem(16px);
      width: auto;

      &:nth-of-type(1) {
        border-left: none;
        padding-left: 0;
      }
    }
  }

  .event-image-container {
    img {
      overflow: hidden;
      width: 100%;
    }
  }

  &.list {
    display: flex;
    height: rem(132px);
    max-width: 100%;

    .event-image-container {
      flex-grow: 0;
      height: rem(132px);
      overflow: hidden;
      width: rem(150px);

      img {
        height: 100%;
        width: auto;
      }
    }

    .md-card-text {
      flex-grow: 1;
      flex-basis: 0;
      padding: rem(24px);
    }
  }

  &.list {
    display: flex;
    height: auto;
    max-width: 100%;

    .event-image-container {
      display: none;
    }

    .event-title h5 {
      padding-left: 40px;
    }

    .md-card-text {
      flex-basis: 0;
      flex-grow: 1;
      padding: rem(16px);
    }

    .details {
      margin-bottom: 0;
    }

    @include min-screen($ipad) {
      .timestamp {
        position: absolute;
        right: rem(16px);
        text-align: right;
        top: rem(16px);
      }

      .details {
        margin-bottom: 0;
        padding-left: rem(40px);

        &.inline {
          border: none;
          padding: 0;
          padding-left: 0;
          width: 100%;

          &:nth-of-type(1) {
            margin-bottom: rem(8px);
          }
        }
      }
    }
  }

  .selected-indicator {
    position: absolute;
    right: rem(16px);
    bottom: rem(16px);

    i {
      color: $secondary-color
    }
  }
}
