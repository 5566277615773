@import '../../scss/imports';

.inventory-card {
  cursor: pointer;
  border-radius: $border-radius;
  margin-bottom: rem(16px);
  min-height: rem(280px);
  position: relative;
  overflow: hidden;

  .top-title {
    background: $white;
    padding: rem(16px 16px 0 16px);

    h4 {
      color: $secondary-text-color;
      font-family: $font-regular;
      font-size: rem(22px);
      margin: 0;
    }
  }

  .card-title {
    align-items: center;
    display: flex;
    text-transform: capitalize;
  }

  .card-subtitle {
    color: rgba(0, 0, 0, 0.6);
    font-size: rem(14px);
    line-height: rem(16px);
    margin-top: rem(4px);
    min-height: rem(34px);
    text-transform: capitalize;
  }

  ul {
    list-style: none;
    padding: rem(16px 16px 32px 16px);
    margin: 0;

    li {
      height: rem(45px);
      margin-bottom: rem(8px);
      position: relative;
      padding-left: rem(40px);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .icon {
      left: 0;
      position: absolute;
      top: rem(3px);
    }

    .data {
      .metric {
        color: #000;
        display: flex;
        font-family: $font-regular;
        font-size: rem(24px);
        line-height: rem(30px);
      }

      .label {
        color: rgba(#000, .55);
        display: flex;
        font-family: $font-regular;
        font-size: rem(12px);
        line-height: rem(16px);
      }
    }
  }
}
