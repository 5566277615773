@import '../../scss/imports';

#cashiers {
  table {
    margin-top: rem(30px);
  }

  .cashier-ul{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
  }

}
