@import '../../scss/imports';

.rollups-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: rem(-20px) 0 0 0;
  padding: 0 rem(16px);

  .rollup-stat-container {
    box-sizing: border-box;

    .rollup-stat {
      box-sizing: border-box;
      margin: rem(8px) 0;
    }
  }

  .vip-stat{
    margin-bottom: rem(8px)
  }

  h2 {
    color: #000;
    font-size: rem(36px);
    margin: 0;
  }

  p {
    color: rgba(0, 0, 0, 0.6);
    font-size: rem(12px);
    margin: rem(8px) 0;
  }
}
