@import '../../scss/imports';

#no-match {
  height: 100vh;
  padding-top: rem(100px);

  h1 {
    color: $secondary-text-color;
    display: inline-block;
    font-size: rem(200px);
    line-height: rem(220px);
  }

  p {
    color: $secondary-text-color;
    font-size: rem(24px);
  }
}
