@import '../../scss/imports';

.chart-legend-container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: rem(4px) rem(2px);


  .chart-legend-item {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: rem(18px);
    max-height: rem(39px);
    margin: rem(18px) rem(10px);
    min-width: rem(86px);
    padding-left: rem(12px);

    .chart-legend-item-data {
      height: 100%;
    }

    .chart-legend-item-name {
      color: rgba(0, 0, 0, 0.55);
      font-size: rem(12px);
      font-weight: normal;
      margin: 0;
      padding: 0;
    }
  }

  .breakdown-legend {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .breakdown-subset {
      margin-left: 20px;
    }
  }
}
