@import '../../scss/imports';

#overview-dialog {
  .wrapper {
    padding-top: rem(80px);
  }

  .dialog-action-container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: rem(25px);
  }
}
