// Material Icons

.material-icons {
  &.md-18 {
    font-size: rem(18px);
  }

  &.md-24 {
    font-size: rem(24px);
  }

  &.md-36 {
    font-size: rem(36px);
  }

  &.md-48 {
    font-size: rem(48px);
  }

  &.md-light {
    color: rgba($blue, 1);
  }

  &.md-light.md-inactive {
    color: rgba($blue, 0.3);
  }

  &.md-dark {
    color: $text-color;
  }

  &.md-dark.md-inactive {
    color: rgba($white, 0.3);
  }

  &.md-icon {
    font-size: rem(24px);
  }
}
