@import '../../scss/imports';

.sidebar {
  background: $body-bg-color;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: rem(16px);
  width: rem(236px);
  z-index: 1;

  &.collapsed {
    width: rem(80px);
  }

  @include max-screen(800px) {
    display: none;
  }
}

.side-menu,
.side-menu-small {
  list-style-type: none;

  .md-list-item {
    position: relative;


  .md-list-tile {
    border-radius: 0 100em 100em 0;
    height: rem(42px);
  }

    .md-list-tile--active {
      background:rgba(255, 86, 34, 0.20);
      border-radius: 0 100em 100em 0;
    }

    &.active {
      background:rgba(255, 86, 34, 0.20);
      border-radius: 0 100em 100em 0;
      font-family: $font-demi;

      /* &:after {
        background: $primary-color;
        content: '';
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: rem(8px);
      } */

      .md-icon,
      .md-text,
      svg {
        color: $primary-color;
      }
    }
  }
}

