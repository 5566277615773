@import '../../scss/imports';

.chart-card {
  break-inside: avoid;
  margin-bottom: rem(16px);
  position: relative;

  .chart-card-title {
    color: $secondary-text-color;
    font-family: $font-demi;
    font-size: rem(22px);
    margin: 0;
    padding: rem(14px 24px);

    @include max-screen($lg) {
      font-size: rem(20px);
    }
  }

  .action-button {
    position: absolute;
    right: rem(4px);
    top: rem(14px);

    .md-icon {
      opacity: .6;
    }
  }

  .chart-card-content {
    padding: rem(16px);
  }

  .md-card-text {
    color: rgba(0, 0, 0, 0.6);
    font-size: rem(22px);
  }

  .bottom-action-container {
    padding: rem(4px 8px 8px 8px);
    text-align: center;

    .button {
      min-width: rem(224px);
    }
  }
}
