@mixin transition() {
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
}

@mixin generate-grid($name) {
  $flexbox-grid-columns: 12;

  .col-#{$name},
  .col-#{$name}-1,
  .col-#{$name}-2,
  .col-#{$name}-3,
  .col-#{$name}-4,
  .col-#{$name}-5,
  .col-#{$name}-6,
  .col-#{$name}-7,
  .col-#{$name}-8,
  .col-#{$name}-9,
  .col-#{$name}-10,
  .col-#{$name}-11,
  .col-#{$name}-12,
  .col-#{$name}-offset-0,
  .col-#{$name}-offset-1,
  .col-#{$name}-offset-2,
  .col-#{$name}-offset-3,
  .col-#{$name}-offset-4,
  .col-#{$name}-offset-5,
  .col-#{$name}-offset-6,
  .col-#{$name}-offset-7,
  .col-#{$name}-offset-8,
  .col-#{$name}-offset-9,
  .col-#{$name}-offset-10,
  .col-#{$name}-offset-11,
  .col-#{$name}-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .col-#{$name} {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  @for $i from 1 through $flexbox-grid-columns {
    .col-#{$name}-#{$i} {
      flex-basis: 100% / $flexbox-grid-columns * $i;
      max-width: 100% / $flexbox-grid-columns * $i;
    }
  }

  @for $i from 0 through $flexbox-grid-columns {
    .col-#{$name}-offset-#{$i} {
      @if $i == 0 {
        margin-left: 0;
      } @else {
        margin-left: 100% / $flexbox-grid-columns * $i;
      }
    }
  }

  .start-#{$name} {
    justify-content: flex-start;
    text-align: start;
  }

  .center-#{$name} {
    justify-content: center;
    text-align: center;
  }

  .end-#{$name} {
    justify-content: flex-end;
    text-align: end;
  }

  .top-#{$name} {
    align-items: flex-start;
  }

  .middle-#{$name} {
    align-items: center;
  }

  .bottom-#{$name} {
    align-items: flex-end;
  }

  .around-#{$name} {
    justify-content: space-around;
  }

  .between-#{$name} {
    justify-content: space-between;
  }

  .first-#{$name} {
    order: -1;
  }

  .last-#{$name} {
    order: 1;
  }
}
