@import '../../scss/imports';

.donut-title {
  font-size: rem(36px);
}

.donut-subtitle {
  color: rgba(0, 0, 0, 0.55);
  font-size: rem(17px);
}



