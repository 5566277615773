@import '../../scss/imports';

.empty-chart-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: rem(250px);
  padding: rem(10px);

  .empty-chart-text {
    box-sizing: border-box;
    color: #bbbbbb;
    font-size: rem(30px);
    margin: rem(29px) 0;
    min-height: rem(85px);
    text-align: center;
  }
}
