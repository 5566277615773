@import 'imports';

.fade-enter {
  opacity: 0;
  position: absolute;
  width: calc(100% - 32px);
}

.fade-enter.fade-enter-active {
  @include transition();
  opacity: 1;
}

.fade-exit {
  @include transition();
  opacity: 1;
  z-index: -1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  z-index: -1;
}

.slide-enter {
  background: $body-bg-color;
  position: absolute;
  transform: translate3d(100%, 0, 0);
  width: calc(100% - 32px);
  z-index: 1;
}

.slide-enter.slide-enter-active {
  @include transition();
  transform: translate3d(0, 0, 0);
}
.slide-exit {
  @include transition();
  opacity: 0;
  z-index: -1;
}

.slide-exit.slide-exit-active {

}

.transition {
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
}
